import { render, staticRenderFns } from "./SbAviTestResultInput.vue?vue&type=template&id=32780680&scoped=true"
import script from "./SbAviTestResultInput.vue?vue&type=script&lang=js"
export * from "./SbAviTestResultInput.vue?vue&type=script&lang=js"
import style0 from "./SbAviTestResultInput.vue?vue&type=style&index=0&id=32780680&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32780680",
  null
  
)

export default component.exports