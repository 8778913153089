var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('sb-max-width',{attrs:{"max-width":"600"}},[_c('p',[_vm._v(" De volgende punten zijn op urgentie gesorteerd voor extra aandacht. Pas de volgorde naar eigen inzicht aan. ")])]),_c('sb-spacer',{attrs:{"height":"40"}}),_c('sb-title',{attrs:{"text":"Sorteer focuspunten","size":"small"}}),_c('sb-spacer',{attrs:{"height":"20"}}),(_vm.value.length === 0)?_c('p',[_vm._v("Geen problematieken toegevoegd.")]):_vm._e(),(_vm.value.length)?_c('sb-list-head',{staticStyle:{"grid-template-columns":"60px auto 150px 30%"}},[_c('sb-list-head-col',{staticStyle:{"grid-column":"1 / span 2"}},[_vm._v(" Problematiek ")]),_c('sb-list-head-col',{staticStyle:{"text-align":"center"}},[_vm._v(" Aantal fouten ")]),_c('sb-list-head-col',[_vm._v(" Technieken ")])],1):_vm._e(),_vm._l((_vm.value),function(problemEntry,index){return _c('sb-list-row',{key:problemEntry.problemId,style:({
      gridTemplateColumns: '40px 60px auto 150px 30%',
      zIndex: _vm.value.length - index,
      position: 'relative',
    })},[_c('sb-list-row-col',[_c('button',{attrs:{"v-if":!_vm.readonly,"type":"button"},on:{"click":function($event){return _vm.swapProblemEntryPositions(index, index - 1)}}},[_c('sb-button',{class:{ 'sort-arrow-disabled': index === 0 },attrs:{"size":"small","naked":""}},[_c('sb-icon',{attrs:{"icon-id":"icon_arrow-up"}})],1)],1)]),_c('sb-list-row-col',[_c('button',{attrs:{"v-if":!_vm.readonly,"type":"button"},on:{"click":function($event){return _vm.swapProblemEntryPositions(index, index + 1)}}},[_c('sb-button',{class:{
            'sort-arrow-disabled': index === _vm.value.length - 1,
          },attrs:{"size":"small","naked":""}},[_c('sb-icon',{attrs:{"icon-id":"icon_arrow-down"}})],1)],1)]),_c('sb-list-row-col',[_vm._v(" "+_vm._s(_vm.get(_vm.getProblemById(problemEntry.problemId), 'title'))+" ")]),_c('sb-list-row-col',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(problemEntry.mistakeAmount)+" ")]),_c('sb-list-row-col',[_c('sb-problems-input-techniques',{attrs:{"items":_vm.getLinkedTechniquesbyProblemId(problemEntry.problemId)}})],1)],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }