var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.active)?[_c('sb-spacer',{attrs:{"height":"40"}}),_c('div',{staticClass:"title-container"},[_c('div',{staticClass:"icon-arrow-left",on:{"click":function($event){_vm.active = undefined}}},[_c('sb-icon',{attrs:{"icon-id":"icon_arrow-left"}})],1),_c('sb-title',{attrs:{"text":`Toetsresultaten ${_vm.active ? '-' : ''} ${
          _vm.getTestLabelByTypename(_vm.get(_vm.active, '__typename')) || ''
        } ${_vm.get(_vm.active, 'level') || ''}`,"size":"small"}})],1),_c('sb-spacer',{attrs:{"height":"20"}})]:_c('sb-divider',{attrs:{"title":"Overzicht toetsresultaten"}}),(!_vm.active)?[_c('sb-table-2',{attrs:{"config":_vm.tableConfig,"data":_vm.tableData},on:{"change":function($event){return _vm.handleTableData($event)},"cell-click-test-date":_vm.setActive}}),_c('sb-spacer',{attrs:{"height":"200"}})]:[_c('sb-student-intake-form',{key:`${_vm.active.id}-${_vm.activeKey}`,ref:"studentIntakeForm",attrs:{"intake-type":_vm.getIntakeType(_vm.active.__typename),"default-data":_vm.active,"mode":_vm.mode,"promise":_vm.promise},on:{"reset":_vm.reset,"submit":_vm.handleSubmit},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}})],(!_vm.active)?_c('sb-bottom-fixed-buttons',{scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('i-button',{attrs:{"size":"large","type":"primary"},on:{"click":function($event){return _vm.$router.push({
            name: 'ResourceManagementStudentsStudentPathAddTestResult',
            query: {
              origin: 'ResourceManagementStudentsStudentPathTestResults',
            },
          })}}},[_vm._v(" Resultaat toevoegen ")])]},proxy:true}],null,false,3015010006)}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }